import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import getConfig from 'next/config';
import { isBrowser } from '~/shared/utils/platform';

const { publicRuntimeConfig } = getConfig();
const browserHistory = typeof window !== 'undefined' ? createBrowserHistory() : null;

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
	config: {
		connectionString: publicRuntimeConfig.appInsightsConnectionString,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
		disableCookiesUsage: true,
	},
});

if (isBrowser && publicRuntimeConfig.appInsightsConnectionString) {
	appInsights.loadAppInsights();
}
